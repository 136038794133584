import React from "react";
import Section from "./Section";
import Card from "./Card";
import { articles } from "../data/Articles";

const ListeArticles = () => {
return (
    <Section>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {articles.map((article, index) => (
            <Card 
            key={index}
            title={article.libelle}
            content={article.description}
            icon={() => {
                return (
                    <>
                        {article.visuel && <img src={article.visuel} alt={article.libelle} className="h-48 object-center" />}
                    </>
                )
            }}
            buttonText="Procéder au paiement"
            url ={article.url}
          />
        ))}
        </div>
    </Section>
)}

export default ListeArticles;
