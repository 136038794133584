import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaEnvelope } from 'react-icons/fa';

const FooterSection = ({ title, links }) => (
  <div className="mb-6 md:mb-0">
    <h3 className="text-lg font-semibold mb-3">{title}</h3>
    <ul>
      {links.map((link, index) => (
        <li key={index} className="mb-2">
          <Link
            to={`/${link.section}${link.tab ? `/${link.tab}` : ''}`}
            className="text-gray-300 hover:text-white transition duration-300"
          >
            {link.text}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => {
  const sections = [
    {
      title: "Le Club",
      links: [        
        { text: "Histoire", section: "club", tab: 'histoire' },
        { text: "Équipe dirigeante", section: "club", tab: 'bureau' },
        { text: "Agenda de la saison", section: "agenda" },
        { text: "Règlement intérieur", section: "club", tab: 'reglement' },
      ]
    },
    {
      title: "Activités",
      links: [
        { text: "Entraînements", section: "entrainements" },
        //{ text: "Compétitions", section: "interclub" },
        { text: "École de badminton", section: "entrainements" },
      ]
    },
    {
      title: "Inscription",
      links: [
        { text: "Modalités d'inscription", section: "inscription", tab: "modalite" },
        { text: "Certificat Médical", section: "inscription", tab: "modalite" },
        { text: "Pass'Sport", section: "inscription", tab: "passSport" },
      ]
    },
    {
      title: "Horaires et créneaux",
      links: [
        { text: "Horaires Adultes", section: "creneaux", tab: "creneauxAdultes" },
        { text: "Tarifs Adultes", section: "creneaux", tab: "creneauxAdultes" },
        { text: "Horaires Jeunes", section: "creneaux", tab: "creneauxJeunes" },
        { text: "Tarifs Jeunes", section: "creneaux", tab: "creneauxJeunes" },
      ]
    },
    {
      title: "Contact",
      links: [
        { text: "Nous contacter", section: "contact", tab: "contact" },
        { text: "Envoyez nous un message", section: "contact", tab: "form" },
        { text: "Réseaux sociaux", section: "contact", tab: "social" },
        { text: "Plan d'accès", section: "acces" },
        { text: "FAQ", section: "FAQ" },
      ]
    },
  ];

  return (
    <footer className="bg-stone-800 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {sections.map((section, index) => (
            <FooterSection 
              key={index} 
              title={section.title} 
              links={section.links} 
            />
          ))}
        </div>
        <div className="mt-8 pt-8 border-t border-stone-700">
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full md:w-1/2 mb-4 md:mb-0">
              <p>&copy; 2024 Badminton Club Eragny. Tous droits réservés.</p>
            </div>
            <div className="w-full md:w-1/2 flex justify-start md:justify-end">
              <a href="https://www.facebook.com/BCEragny" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white mr-4">
                <FaFacebookF />
              </a>
              <a href="https://www.instagram.com/badminton_eragny/" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white mr-4">
                <FaInstagram />
              </a>
              <Link to="/contact" className="text-gray-300 hover:text-white">
                <FaEnvelope />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;