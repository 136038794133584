import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import { motion } from 'framer-motion';
import { FaAward, FaGraduationCap, FaUsers, FaHandshake } from 'react-icons/fa';

// Importez les autres images nécessaires ici
import Section from '../components/Section';
import Card from '../components/Card';
import Bureau from '../components/Bureau';
import { historyEvents } from '../data/Events';
import Boutique from '../components/ListeArticles';
import { useParams } from 'react-router-dom';

const TimelineEvent = ({ date, title, description, status }) => (
  <div className={`flex mb-8 ${status === 'Passé' ? 'opacity-50' : ''}`}>
    <div className="flex flex-col items-center mr-4">
      <div className={`w-4 h-4 ${status === 'Passé' ? 'bg-gray-400' : 'bg-red-800'} rounded-full`}></div>
      <div className={`w-1 h-full ${status === 'Passé' ? 'bg-gray-400' : 'bg-red-800'}`}></div>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 flex-1">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-xl font-bold text-red-800">{date}</h3>
        <span className={`${status === 'Passé' ? 'bg-gray-500' : 'bg-green-500'} text-white text-xs font-bold mr-2 px-2.5 py-0.5 rounded`}>
          {status}
        </span>
      </div>
      <h4 className="text-lg font-semibold mb-2">{title}</h4>
      <p className="text-gray-700">{description}</p>
    </div>
  </div>
);

const VirtualTour = () => (
  <div className="aspect-w-16 aspect-h-9">
    <iframe src="https://www.google.com/maps/embed?pb=!3m2!1sen!2sfr!4v1724798711302!5m2!1sen!2sfr!6m8!1m7!1sPf1bK2z2Bu_5STPxpEbdgg!2m2!1d49.02141677790688!2d2.111934957393812!3f75.09783546343078!4f0.3226142791688602!5f0.7820865974627469"
     width="100%"
      height="450"
      style={{border:0}} 
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"></iframe>
  </div>
);

const Club = ({activeTab, setActiveTab, setCurrentSection, currentSection }) => {
  const [modalContent, setModalContent] = useState(null);
  const {tab} = useParams();

  const handleButtonClick = (content) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  useEffect(() => {
    if(!tab) setActiveTab(Object.keys(tabContent)[0]);
    else if(!!tab && tab !== activeTab) {setActiveTab(tab)}
  }, [currentSection,tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);

  const Modal = ({ content, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4">{content.title}</h2>
        <p>{content.description}</p>
        <button 
          onClick={onClose}
          className="mt-4 bg-red-800 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
        >
          Fermer
        </button>
      </div>
    </div>
  );

  const tabContent = {
    histoire: (
      <Section title="Notre Histoire">
        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">Chronologie du Club</h3>
          {historyEvents.map((event, index) => (
            <TimelineEvent key={index} {...event} />
          ))}
        </div>
        {/*
        <div>
          <h3 className="text-2xl font-semibold mb-4">Palmarès</h3>
          <ul className="list-disc pl-5">
            <li>Champion régional 2020</li>
            <li>Finaliste coupe départementale 2019</li>
            <li>Vainqueur du tournoi international junior 2018</li>
          </ul>
        </div>*/}
      </Section>
    ),
    culture: (
      <Section title="Notre Culture" bgColor="bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-8">
          <Card 
            title="Nos Valeurs"
            content="Excellence, Esprit d'équipe, Respect, Innovation - Ces valeurs guident chacune de nos actions."
            icon={FaAward}
            buttonText="En savoir plus"
            onButtonClick={() => handleButtonClick({
              title: "Nos Valeurs",
              description: "Nos valeurs sont au cœur de tout ce que nous faisons. L'excellence nous pousse à toujours donner le meilleur de nous-mêmes. L'esprit d'équipe nous unit dans la victoire comme dans la défaite. Le respect est la base de toutes nos interactions. L'innovation nous permet de rester à la pointe du badminton moderne."
            })}
          />
          <Card 
            title="Esprit de Convivialité"
            content="Nous cultivons un environnement convivial où chacun peut s'épanouir dans la pratique du badminton."
            icon={FaUsers}
            buttonText="Notre philosophie"
            onButtonClick={() => handleButtonClick({
              title: "La Convivialité au Centre",
              description: "Dans notre centre, la convivialité est au cœur de toutes nos activités. Nous favorisons l'esprit d'équipe, l'entraide, et la bonne humeur lors de nos entraînements et compétitions. Que vous soyez débutant ou expérimenté, vous trouverez un cadre bienveillant où partager votre passion pour le badminton."
            })}
          />

          <Card 
            title="Centre de Formation"
            content="Notre centre de formation est dédié au développement des jeunes talents du badminton."
            icon={FaGraduationCap}
            buttonText="Découvrir"
            onButtonClick={() => handleButtonClick({
              title: "Notre Centre de Formation",
              description: "Notre centre de formation offre un programme adapté pour les jeunes de 8 à 18 ans. Nous mettons l'accent sur l'entraînement technique et la préparation physique, encadrés par des entraîneurs qualifiés. Notre objectif est de permettre à chaque jeune de progresser à son rythme et de développer ses compétences dans un environnement bienveillant et motivant."
            })}
          />
          <Card 
            title="Esprit d'Équipe et Solidarité"
            content="Nous valorisons l'esprit d'équipe et la solidarité au cœur de notre club."
            icon={FaHandshake}
            buttonText="En savoir plus"
            onButtonClick={() => handleButtonClick({
              title: "Esprit d'Équipe et Solidarité",
              description: "Dans notre club, chaque joueur compte. Nous encourageons un esprit d'équipe fort, où l'entraide et le soutien mutuel sont essentiels. Chaque membre, qu'il soit débutant ou confirmé, bénéficie de l'appui de ses coéquipiers et de ses entraîneurs, créant ainsi une atmosphère où chacun progresse et s'épanouit ensemble."
            })}
          />

        </div>
      </Section>
    ),
    
    
    reglement: (
      <Section title="Règlement Intérieur" bgColor="bg-white">
        <div className="space-y-6">
          <div>
            <h3 className="text-2xl font-semibold mb-2">Article 1 : Objet</h3>
            <p>Le présent règlement intérieur a pour objet de préciser les règles de fonctionnement du Club de Badminton d'Éragny. Tous les membres s'engagent à respecter ce règlement.</p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Article 2 : Adhésion</h3>
            <ol className="list-decimal pl-5 space-y-2">
              <li>Cotisation : L'adhésion au club est subordonnée au paiement d'une cotisation annuelle dont le montant est fixé chaque année par le bureau.</li>
              <li>Licence : Chaque membre doit être titulaire d'une licence fédérale en cours de validité.</li>
              <li>Respect des conditions : L'adhésion implique l'acceptation des statuts du club et du présent règlement intérieur.</li>
            </ol>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Article 3 : Comportement des Membres</h3>
            <ol className="list-decimal pl-5 space-y-2">
              <li>Respect des autres : Les membres doivent adopter une attitude respectueuse envers les autres joueurs, les entraîneurs, les membres du bureau, et les adversaires lors des compétitions.</li>
              <li>Fair-play : Le fair-play doit être observé en toutes circonstances, tant lors des entraînements que des compétitions.</li>
              <li>Sécurité : Les consignes de sécurité doivent être respectées pour éviter tout risque de blessure.</li>
            </ol>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Article 4 : Utilisation des Installations</h3>
            <ol className="list-decimal pl-5 space-y-2">
              <li>Accès aux terrains : Les terrains sont réservés aux membres du club selon les horaires définis. Il est interdit d'accéder aux terrains en dehors de ces horaires sans autorisation préalable.</li>
              <li>Matériel : Les membres doivent prendre soin du matériel mis à disposition (filets, volants, raquettes). Tout dommage devra être signalé au responsable du matériel.</li>
              <li>Propreté : Chacun est tenu de laisser les installations (terrains, vestiaires) dans un état de propreté correct après utilisation.</li>
            </ol>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Article 5 : Entraînements et Compétitions</h3>
            <ol className="list-decimal pl-5 space-y-2">
              <li>Assiduité : Les membres doivent être ponctuels et réguliers aux entraînements. Toute absence répétée devra être justifiée.</li>
              <li>Compétitions : Les membres engagés en compétition doivent respecter les engagements pris et honorer les convocations. En cas d'empêchement, ils doivent prévenir le plus tôt possible le responsable des compétitions.</li>
              <li>Tenue : Une tenue adaptée à la pratique du badminton est exigée lors des entraînements et des compétitions.</li>
            </ol>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Article 6 : Sanctions</h3>
            <ol className="list-decimal pl-5 space-y-2">
              <li>Non-respect du règlement : En cas de non-respect du présent règlement, des sanctions pourront être appliquées par le bureau, allant du simple avertissement à l'exclusion temporaire ou définitive du club.</li>
              <li>Procédure disciplinaire : En cas de faute grave, une procédure disciplinaire pourra être engagée conformément aux statuts du club.</li>
            </ol>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Article 7 : Modification du Règlement</h3>
            <p>Le présent règlement intérieur peut être modifié à tout moment par décision du bureau. Les membres seront informés de toute modification par voie d'affichage et sur le site internet du club.</p>
          </div>
        </div>
      </Section>
    ),
    bureau: (
      <Bureau handleButtonClick={handleButtonClick}/>
    ),
    gymnase: (
      <Section title="Le Gymnase" bgColor="bg-white">
        <p className="mb-4">Découvrez nos installations de pointe à travers cette visite virtuelle.</p>
        <VirtualTour />
        <div className="mt-8">
          <h3 className="text-2xl font-semibold mb-4">Nos Installations</h3>
          <ul className="list-disc pl-5">
            <li>7 terrains de badminton aux normes internationales</li>
            <li>Salle supplémentaire de 4 terrains utilsé lors des tournois</li>
            <li>Le club house : une salle dédiée pour se retrouver, célébrer, et accueillir lors d'évènements comme les interclubs</li>
            <li>Vestiaires modernes et spacieux</li>
          </ul>
        </div>
      </Section>
    ),
    /*boutique: (
      <Boutique handleButtonClick={handleButtonClick}/>
    ),*/
  };

  const tabTitle = {
    reglement: 'Règlement Intérieur',
  };

  return (
    <>
      <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
        <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
        <div className="text-gray-800 py-40">
          <div className="container mx-auto px-4">
            <h1 className="text-5xl font-bold text-center mb-12">Badminton Club Eragny</h1>
            <p className="text-xl text-center mb-12">Découvrez l'histoire, la culture et les installations de notre club</p>

            <div className="flex flex-wrap justify-center mb-8 ">
              {Object.keys(tabContent).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 m-2 rounded ${
                    activeTab === tab ? 'bg-red-800 text-white' : 'bg-stone-200 text-gray-700'
                  }`}
                >
                  {Object.keys(tabTitle).includes(tab) ? tabTitle[tab] : tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}              
            >
              {tabContent[activeTab]}
            </motion.div>
          </div>
        </div>
      </div>
      {modalContent && <Modal content={modalContent} onClose={closeModal} />}
    </>
  );
};

export default Club;