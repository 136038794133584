import React from 'react';
import Header from '../components/Header';
import Gallery from '../components/Gallery';
import imgHome from '../images/home/landingPage.webp';
import imgTeam from '../images/home/team.jpg';
import imgCourts from '../images/home/badmintoncourt.jpg';
import imgTournament from '../images/home/tournament.jpg';
import imgYoungs from '../images/home/youngTeam.jpg';
import CurrentEvents from '../components/CurrentEvents';
import { FaUsers, FaChalkboardTeacher, FaTrophy, FaChild } from 'react-icons/fa';
import Partenaires from '../components/Partenaires';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <div className="text-4xl text-red-800 mb-4 flex justify-center">
      <Icon />
    </div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Accueil = ({ setCurrentSection, currentSection,setActiveTab }) => (
  <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHome})`}}>
    <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
    <div className="text-white pt-40 pb-10 md:pt-40 md:pb-20 lg:py-60 lg:pb-40 xl:pb-60">
      <div className="container mx-auto text-center px-4  bg-stone-900 bg-opacity-30">
        <h1 className="text-6xl font-bold mb-4">Badminton Club Eragny</h1>
        <p className="text-xl mb-8">
        Découvrez le badminton sous un nouveau jour avec un club dynamique et accueillant !
        Vivez votre passion du badminton dans une ambiance conviviale. 
        Que vous soyez débutant ou confirmé, rejoignez-nous pour progresser et vous épanouir 
        dans ce sport passionnant.
        </p>
        
      </div>
      <div className="container mx-auto text-center px-4 ">
      <button className="bg-white text-red-800 px-6 py-3 mt-6 rounded-full text-lg font-semibold hover:bg-red-600 hover:text-white transition duration-300"
        onClick={() => { setCurrentSection('club');}} >
          Découvrez notre club
        </button>
        </div>
    </div>
    <CurrentEvents setCurrentSection={setCurrentSection} setActiveTab={setActiveTab}/>
    <Gallery 
      title="Notre club en images" 
      images={[
        { src: imgCourts, alt: "Terrain de badminton" },
        { src: imgTournament, alt: "Tournoi de badminton" },
        { src: imgYoungs, alt: "Jeunes joueurs de badminton" },
        { src: imgTeam, alt: "Groupe de joueurs" }
      ]}
    />
    <div className="py-16 bg-white">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Pourquoi choisir le BCE ?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <FeatureCard 
            icon={FaUsers}
            title="Entraînements Adaptés"
            description="Que vous soyez débutant ou joueur confirmé, des coachs qualifiés vous accompagnent pour progresser à votre rythme."
          />
          <FeatureCard 
            icon={FaChalkboardTeacher}
            title="Ambiance Conviviale"
            description="Rencontrez des passionnés de badminton et créez des liens d'amitié tout en pratiquant votre sport favori."
          />
          <FeatureCard 
            icon={FaTrophy}
            title="Compétitions et Loisirs"
            description="Participez à des tournois passionnants ou profitez simplement des séances de jeu libre pour vous amuser."
          />
          <FeatureCard 
            icon={FaChild}
            title="Installations Modernes"
            description="Jouez dans des installations de qualité, avec 7 terrains bien entretenus et tout l'équipement nécessaire."
          />
          <FeatureCard 
            icon={FaChild}
            title="Horaires Flexibles"
            description="Trouvez des créneaux qui correspondent à votre emploi du
temps."
          />
        </div>
        <button className="bg-red-800 text-white px-6 py-3 mt-6 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300"
        onClick={() => { setCurrentSection('inscription');}} >
          REJOIGNEZ-NOUS
        </button>
      </div>
    </div>
    <Partenaires/>
  </div>
);

export default Accueil;