import { Link } from "react-router-dom";

const faqData = [
    {
      question: "Quels sont les horaires d'ouverture du club ?",
      answer: ({setCurrentSection,setActiveTab}) => (<>
        <p>"Le club est ouvert du lundi au vendredi de 18h à 22h, et le samedi de 10h à 18h. Les horaires peuvent varier selon les créneaux d'entraînement et les compétitions."</p>
      <Link
        to='/creneaux/creneauxAdultes'
        className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
      >
        Voir les créneaux
      </Link>
      </>)
    },
    {
      question: "Comment puis-je m'inscrire au club ?",
      answer:({setCurrentSection,setActiveTab}) => (<>
      <p>"Vous pouvez vous inscrire directement sur notre site web dans la section 'Inscription', ou venir nous rencontrer au club pendant les horaires d'ouverture. N'oubliez pas d'apporter un certificat médical pour la pratique du badminton."</p>
      <Link
        to='/inscription/modalite'
        className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
      >
        inscrivez vous en ligne
      </Link>
      </>)
      
    },
    {
      question: "Proposez-vous des cours pour débutants ?",
      answer: ({setCurrentSection,setActiveTab}) => (<>
      <p>"Oui, nous proposons des cours pour tous les niveaux, y compris les débutants. Nos entraîneurs qualifiés vous aideront à progresser à votre rythme."</p>
      <Link
        to='/entrainements'
        className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
      >
        Voir les entraineurs
      </Link>
      <Link
        to='/creneaux/creneauxAdultes'
        className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
      >
        Voir les créneaux
      </Link>
      </>)
    },
    {
      question: "Faut-il apporter son propre équipement ?",
      answer:({setCurrentSection,setActiveTab}) => (<>
      <p>"Pour commencer, vous pouvez emprunter des raquettes au club. Cependant, nous recommandons d'avoir votre propre raquette à long terme. Des volants sont fournis pour les entraînements et les matchs."</p>
      <a 
        href="https://www.lardesports.com/"
        target="_blank" 
        rel="noopener noreferrer"
        className="inline-block bg-red-800 text-white px-4 py-2 ml-2 mt-6 rounded-full hover:bg-red-700 transition duration-300"
      >
        Equipez-vous auprès de notre partenaire
      </a>
      </>)
    },
    {
      question: "Y a-t-il un âge minimum pour s'inscrire ?",
      answer: ({setCurrentSection,setActiveTab}) => (<>
      <p>"Nous accueillons les joueurs à partir de 6 ans dans notre école de badminton. Pour les adultes, il n'y a pas de limite d'âge."</p>
      <Link
        to='/creneaux/creneauxJeunes'
        className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
      >
        Voir les créneaux
      </Link>
      </>)
    },
    {
      question: "Organisez-vous des tournois ?",
      answer: ({setCurrentSection,setActiveTab}) => (<>
      <p>"Oui, nous organisons régulièrement des tournois internes et participons à des compétitions départementales et régionales. C'est une excellente façon de progresser et de rencontrer d'autres joueurs."</p>
      <Link
        to='/agenda'
        className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
      >
        Voir l'agenda de la saison
      </Link>
      </>)
    },{
        question: "Qu'est-ce que le Pass'Sport et comment puis-je en bénéficier ?",
        answer: ({setCurrentSection, setActiveTab}) => (
          <>
            <p>Le Pass'Sport est une allocation de 50€ par enfant pour financer tout ou partie de son inscription dans une association sportive. Il s'adresse aux enfants de 6 à 17 ans bénéficiaires de l'allocation de rentrée scolaire, de l'allocation d'éducation de l'enfant handicapé ou de l'allocation aux adultes handicapés (entre 16 et 18 ans).</p>
            <Link
              to='/inscription/passSport'
              className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
            >
              En savoir plus sur le Pass'Sport
            </Link>
          </>
        )
      },
      {
        question: "Quels sont les tarifs d'adhésion au club ?",
        answer: ({setCurrentSection, setActiveTab}) => (
          <>
            <p>Nos tarifs varient selon les catégories (jeunes, adultes, compétiteurs) et les formules choisies. Ils incluent la licence fédérale et l'accès aux créneaux correspondants.</p>            
            <Link
              to='/creneaux/creneauxAdultes'
              className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
            >
              Voir les tarifs détaillés
            </Link>
          </>
        )
      },
      {
        question: "Proposez-vous des séances d'essai ?",
        answer: () => (
          <p>Oui, nous proposons 2 séances d'essai gratuites pour vous permettre de découvrir notre club et la pratique du badminton. Il suffit de vous présenter à l'un de nos créneaux avec une tenue de sport. Nous vous prêterons une raquette pour l'occasion.</p>
        )
      },
      {
        question: "Comment se déroule une séance type d'entraînement ?",
        answer: () => (
          <p>Une séance type dure généralement 1h30 à 2h. Elle commence par un échauffement, suivi d'exercices techniques et/ou tactiques, et se termine par des matchs. L'intensité et le contenu varient selon le niveau du groupe et les objectifs de la séance.</p>
        )
      },
      /*{
        question: "Le club propose-t-il des stages pendant les vacances scolaires ?",
        answer: ({setCurrentSection}) => (
          <>
            <p>Oui, nous organisons régulièrement des stages pendant les vacances scolaires, aussi bien pour les jeunes que pour les adultes. C'est une excellente occasion de progresser de manière intensive.</p>
            <button 
              className="bg-red-800 text-white px-4 py-2 mt-6 rounded-full text-sm font-semibold hover:bg-red-700 transition duration-300"
              onClick={() => setCurrentSection('Agenda')}
            >
              Voir le calendrier des stages
            </button>
          </>
        )
      },
      {
        question: "Comment puis-je devenir bénévole ou m'impliquer davantage dans la vie du club ?",
        answer: () => (
          <p>Nous sommes toujours ravis d'accueillir de nouveaux bénévoles ! Vous pouvez vous impliquer de diverses manières : aide à l'organisation de tournois, encadrement des jeunes, participation aux événements du club, etc. N'hésitez pas à en parler à un membre du bureau ou à un entraîneur pour plus d'informations.</p>
        )
      },*/
      {
        question: "Le club propose-t-il des partenariats ou des réductions chez des équipementiers ?",
        answer: () => (
          <>
            <p>Oui, nous avons des partenariats avec des équipementiers qui offrent des réductions à nos adhérents. Vous pouvez bénéficier de ces avantages pour l'achat de raquettes, chaussures, et autres équipements de badminton.</p>
            <a 
              href="https://www.lardesports.com/"
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-block bg-red-800 text-white px-4 py-2 mt-6 rounded-full hover:bg-red-700 transition duration-300"
            >
              Découvrir notre partenaire équipementier
            </a>
          </>
        )
      }
  ];

  export {faqData};