import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import { motion, AnimatePresence } from 'framer-motion';
import { FaEnvelope, FaPhone, FaFacebookF, FaInstagram, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import { useParams } from 'react-router-dom';

const ContactInfo = () => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h2 className="text-2xl font-bold mb-4">Nos coordonnées</h2>
    <div className="flex items-center mb-3">
      <FaEnvelope className="mr-2 text-red-800" />
      <a href="mailto:secretariatbceragny@gmail.com" className="text-red-800 hover:underline">secretariatbceragny@gmail.com</a>
    </div>
    <div className="flex items-center">
      <FaPhone className="mr-2 text-red-800" />
      <a href="tel:+33652757503" className="text-red-800 hover:underline">+33 6 52 75 75 03</a>
    </div>
  </div>
);

const SocialMedia = () => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h2 className="text-2xl font-bold mb-4">Suivez-nous</h2>
    <div className="flex space-x-4">
      <a href="https://www.facebook.com/BCEragny" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
        <FaFacebookF size={24} />
      </a>
      <a href="https://www.instagram.com/badminton_eragny/" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-pink-800">
        <FaInstagram size={24} />
      </a>
    </div>
  </div>
);

const Popin = ({ message, type, onClose }) => (
  <motion.div
    initial={{ opacity: 0, y: -50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -50 }}
    className="fixed bottom-2 left-1 transform -translate-x-1/2 bg-white rounded-lg shadow-xl p-6 z-50"
  >
    <div className="flex items-center">
      {type === 'success' ? (
        <FaCheckCircle className="text-green-800 text-3xl mr-4" />
      ) : (
        <FaExclamationTriangle className="text-red-900 text-3xl mr-4" />
      )}
      <p className={`text-lg ${type === 'success' ? 'text-green-800' : 'text-red-800'}`}>{message}</p>
    </div>
    <button
      onClick={onClose}
      className="mt-4 bg-stone-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition duration-300"
    >
      Fermer
    </button>
  </motion.div>
);

const ContactForm = () => {
  const [popin, setPopin] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      nom: e.target.nom.value,
      prenom: e.target.prenom.value,
      from_name: `${e.target.nom.value} ${e.target.prenom.value}`,
      from_email: e.target.email.value,
      phone: e.target.telephone.value,
      message: e.target.message.value
    };

    emailjs.send('service_zimmv0d', 'template_q1ugu78', templateParams, 'RqI6Y4C0UlMjlLyH-')
      .then((result) => {
        console.log(result.text);
        setPopin({ type: 'success', message: 'Message envoyé avec succès !' });
        e.target.reset();
      }, (error) => {
        console.log(error.text);
        setPopin({ type: 'error', message: 'Une erreur est survenue, veuillez réessayer.' });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-4 bg-white p-6 rounded-lg shadow-md">
        <div>
          <label htmlFor="nom" className="block mb-1 font-medium text-gray-700">Nom</label>
          <input type="text" id="nom" name="nom" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-800" required />
        </div>
        <div>
          <label htmlFor="prenom" className="block mb-1 font-medium text-gray-700">Prénom</label>
          <input type="text" id="prenom" name="prenom" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-800" required />
        </div>
        <div>
          <label htmlFor="email" className="block mb-1 font-medium text-gray-700">Email</label>
          <input type="email" id="email" name="email" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-800" required />
        </div>
        <div>
          <label htmlFor="telephone" className="block mb-1 font-medium text-gray-700">Téléphone</label>
          <input type="tel" id="telephone" name="telephone" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-800" required />
        </div>
        <div>
          <label htmlFor="message" className="block mb-1 font-medium text-gray-700">Message</label>
          <textarea id="message" name="message" rows="4" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-800" required></textarea>
        </div>
        <button type="submit" className="w-full bg-red-800 text-white px-6 py-3 rounded-md hover:bg-red-700 transition duration-300">Envoyer</button>
      </form>
      <AnimatePresence>
        {popin && (
          <Popin
            message={popin.message}
            type={popin.type}
            onClose={() => setPopin(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const Contact = ({ setCurrentSection, currentSection, activeTab, setActiveTab }) => {
  const tabContent = {
    contact: <ContactInfo />,
    social: <SocialMedia />,
    form: <ContactForm />
  };

  const {tab} = useParams();
  useEffect(() => {
    if(!tab) setActiveTab(Object.keys(tabContent)[0]);
    else if(!!tab && tab !== activeTab) {setActiveTab(tab)}
  }, [currentSection,tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);
  
  const tabTitle = {
    contact: "Coordonnées",
    social: "Réseaux sociaux",
    form: "Formulaire de contact"
  };

  return (
    <>
      <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
        <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
        <div className="text-gray-800 py-40">
          <div className="container mx-auto px-4">
            <h1 className="text-5xl font-bold text-center mb-12">Contactez-nous</h1>

            <div className="flex justify-center mb-8">
              {Object.keys(tabContent).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 mx-2 rounded ${
                    activeTab === tab ? 'bg-red-800 text-white' : 'bg-stone-200 text-gray-700'
                  }`}
                >
                  {Object.keys(tabTitle).includes(tab) ? tabTitle[tab] : tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="max-w-lg mx-auto"
            >
              {tabContent[activeTab]}
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;