import React from "react";

const Section = ({ title, children, bgColor = 'bg-white' }) => (
    
    <section className={`${bgColor} py-4`}>
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold  text-center mb-8">{title}</h2>
        {children}
      </div>
    </section>
  );

  export default Section;