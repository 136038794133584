import React from 'react';

const PartnerLogo = ({ name, logo, website }) => (
  <a 
    href={website} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="flex items-center justify-center p-4 bg-transparent  hover:shadow-lg transition duration-300"
  >
    <img src={logo} alt={`Logo ${name}`} className="max-h-40 max-w-full object-contain" />
  </a>
);

const Partenaires = () => {
  const partners = [
    {
      name: "Lardesport",
      logo: require("../images/partenaires/lardesports.png"), // Assurez-vous d'avoir cette image
      website: "https://www.lardesports.com/"
    },
    {
      name: "Fédération Française de Badminton",
      logo: require("../images/partenaires/ffbad.png"), // Assurez-vous d'avoir cette image
      website: "https://www.ffbad.org"
    },
    {
      name: "Comité Départemental de Badminton du Val d'Oise",
      logo: require("../images/partenaires/cdbvo.png"), // Assurez-vous d'avoir cette image
      website: "https://cdbvo.fr"
    }
  ];

  return (
    <section className="py-16 bg-stone-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8">Nos Partenaires</h2>
        <div className="flex flex-wrap justify-center items-center gap-20">
          {partners.map((partner, index) => (
            <PartnerLogo key={index} {...partner} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partenaires;