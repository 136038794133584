import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';

const StatusBadge = ({ status }) => {
  const colors = {
    'À venir': 'bg-green-500',
    'En cours': 'bg-red-800',
    'Terminé': 'bg-gray-500'
  };
  return (
    <span className={`${!!colors[status]?colors[status]:'bg-yellow-500'} text-white text-xs font-bold mr-2 px-2.5 py-0.5 rounded`}>
      {status}
    </span>
  );
};

const TimelineEvent = ({ date, title, description, status, isHighlighted }) => (
  <div className={`flex mb-8 ${status === 'Terminé' ? 'opacity-50' : ''} ${isHighlighted ? 'scale-105 shadow-lg' : ''}`}>
    <div className="flex flex-col items-center mr-4">
      <div className={`w-4 h-4 ${status === 'Terminé' ? 'bg-gray-400' : (isHighlighted ?'bg-red-800':'bg-gray-700')} rounded-full`}></div>
      <div className={`w-1 h-full ${status === 'Terminé' ? 'bg-gray-400' : (isHighlighted ?'bg-red-800':'bg-gray-700')}`}></div>
    </div>
    <div className={`bg-white rounded-lg shadow-md p-6 flex-1 ${isHighlighted ? 'border-2 border-red-800' : ''}`}>
      <div className="flex justify-between items-center mb-2">
        <h3 className={`text-xl font-bold ${isHighlighted ? 'text-red-800':'text-gray-700'}`}>{date}</h3>
        <StatusBadge status={status} />
      </div>
      <h4 className="text-lg font-semibold mb-2">{title}</h4>
      <p className="text-gray-700">{description}</p>
    </div>
  </div>
);

const Accordion = ({ title, children, isOpen, onClick,color,hoverColor }) => (
  <div className='mb-4'>
    <button onClick={onClick} className={`w-full flex rounded-lg text-left  font-bold text-white text-lg p-2 ${color} hover:${hoverColor}`}>
      <span className="grow pl-2">{title}</span>
      {isOpen && <FaChevronCircleDown className="mt-1"/>}
      {!isOpen && <FaChevronCircleUp className="mt-1"/>}
    </button>
    {isOpen && <div className="p-4">{children}</div>}
  </div>
);

function getEventStatus(eventDate, eventStatus) {
  const currentDate = new Date();
  const eventDateObj = new Date(eventDate);
  
  
  // Comparer les dates sans tenir compte de l'heure
  currentDate.setHours(0, 0, 0, 0);
  eventDateObj.setHours(0, 0, 0, 0);

  if (eventDateObj < currentDate) {
    return "Terminé";
  } else if (eventDateObj.getTime() === currentDate.getTime()) {
    return "En cours";
  } else {
    return (!!eventStatus)?eventStatus:"À venir";
  }
}

const Calendrier = ({ setCurrentSection, currentSection }) => {
  const [isPastEventsOpen, setIsPastEventsOpen] = useState(false);
  const [isIncommingEventsOpen, setIsIncommingEventsOpen] = useState(true);

    useEffect(() => {
      window.scrollTo(0, 0);
      setIsIncommingEventsOpen(true);
    }, [currentSection]);

    const events = [
    {
        date: new Date("2024-08-08"),
        title: "Début des inscriptions",
        description: "Top départ pour les inscriptions de la saison 2024 / 2025.",
    },{
        date: new Date("2024-09-08"),
        title: "Forum des associations",
        description: "Venez nous rencontrer au Forum des Associations ! Découvrez notre club, essayez le badminton et profitez de nos offres spéciales d'inscription. Une journée conviviale pour petits et grands de 10h à 17h.",
    },
    {
        title: "Reprise du Badminton",
        description: "Le BCE vous souhaite une bonne rentrée ! Le badminton reprend le lundi 9 septembre à 20h.",
        date: new Date("2024-09-09"),   // Ajustez l'année si nécessaire
    },
    {
        date: new Date("2024-10-06"),
        title: "Tournoi interne de Bienvenue",
        description: "Rejoignez-nous pour notre grand Tournoi de Bienvenue ! L'occasion parfaite de rencontrer tous les membres du club, de jouer dans une ambiance festive et de découvrir les projets excitants de la saison à venir.",
    },
    {
        date: new Date("2024-10-06"),
        title: "Intervention Lardesport",
        description: "Ne manquez pas l'intervention exclusive de Jimmy Jouhannet, notre partenaire Lardesport ! Découvrez les dernières innovations en équipement de badminton et profitez de conseils d'expert pour améliorer votre jeu.",
    },
    {
        date: new Date("2024-11-30"),
        title: "Téléthon Blackminton",
        description: "Une nuit magique de badminton dans le noir pour la bonne cause ! Rejoignez-nous pour cette expérience unique de Blackminton et soutenez le Téléthon. Défis, rires et solidarité au programme !",
        endDate: new Date("2024-11-30"),
        status: "Date modifiée",
    },
    /*{
        date: new Date("2024-12-15"),
        title: "Mini tournoi externe de simple",
        description: "Affûtez vos raquettes pour notre mini tournoi de simple ! Une journée intense de compétition ouverte aux clubs voisins. L'occasion parfaite de tester vos compétences et de rencontrer de nouveaux adversaires.",
    },*/
    {
        date: new Date("2025-01-18"),
        title: "Tournoi départemental vétéran",
        description: "Le badminton n'a pas d'âge ! Nos vétérans montrent l'exemple lors de ce tournoi départemental. Deux jours de compétition acharnée, de fair-play et de bonne humeur. Que le meilleur gagne !",
        endDate: new Date("2025-01-19"),
        //status: "À confirmer",
    },
    {
        date: new Date("2025-03-23"),
        title: "Tournoi interne jeunes/parents",
        description: "Un événement familial incontournable ! Parents et enfants s'affrontent dans la bonne humeur. Matchs amusants, remise de coupes et médailles, et beaucoup de rires garantis. Une journée mémorable en perspective !",
    },
    /*{
        date: new Date("2025-06-14"),
        title: "Tournoi des Plumes Rouges (TPR)",
        description: "Notre célèbre tournoi des Plumes Rouges revient ! Cet événement tant attendu rassemble des joueurs de tous niveaux pour deux jours de compétition intense et conviviale. Préparez-vous à des matchs palpitants et une ambiance festive !",
        endDate: new Date("2025-06-22"),
        status: "Date modifiée",
    },*/
    {
        date: new Date("2025-07-05"), // Date approximative, à ajuster
        title: "Tournoi interne de fin de saison",
        description: "Célébrons ensemble la fin de cette incroyable saison ! Notre traditionnel tournoi interne est l'occasion parfaite pour tous les membres du club de se retrouver, de jouer dans la bonne humeur et de partager un dernier moment convivial avant les vacances d'été.",
        status: "Date modifiée",
    },
    ];

  // Mettre à jour le statut des événements et les trier
  const updatedEvents = events.map(event => ({
    ...event,
    status: getEventStatus(event.date, event.status)
  })).sort((a, b) => a.date - b.date);

  const upcomingAndOngoingEvents = [...updatedEvents].filter(event => event.status !== 'Terminé');

  const pastEvents = [...updatedEvents].filter(event => event.status === 'Terminé');

  // Trouver le prochain événement à venir
  const nextEvent = upcomingAndOngoingEvents.find(event => event.status === 'À venir' || event.status === 'En cours' );
  const nextEventIndex = nextEvent ? upcomingAndOngoingEvents.indexOf(nextEvent) : -1;

  return (
    
    <>
      <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
        <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
        <div className="text-gray-800 py-40">
          <div className="container mx-auto text-center px-4">
            <h1 className="text-6xl font-bold mb-10">Calendrier <br/><span className="text-4xl font-normal">de la saison 2024/2025</span></h1>
            <div className="max-w-3xl mx-auto">
            
            <Accordion
                title="Événements à venir"
                isOpen={isIncommingEventsOpen}
                onClick={() => setIsIncommingEventsOpen(!isIncommingEventsOpen)}
                color="bg-red-800"
                hoverColor="bg-red-900"
              >
                {upcomingAndOngoingEvents.length > 0 ? upcomingAndOngoingEvents.map((event, index) => (
                <TimelineEvent
                  key={index}
                  date={event.date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })}
                  title={event.title}
                  description={event.description}
                  status={event.status}
                  isHighlighted={index === nextEventIndex}
                />
              )): (
                <p>Aucun événement passé.</p>
              )}
              </Accordion>

              
              <Accordion
                title="Événements passés"
                isOpen={isPastEventsOpen}
                onClick={() => setIsPastEventsOpen(!isPastEventsOpen)}
                color="bg-gray-500"
                hoverColor="bg-gray-700"
              >
                {pastEvents.length > 0 ? (
                  pastEvents.map((event, index) => (
                    <TimelineEvent
                      key={index}
                      date={event.date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })}
                      title={event.title}
                      description={event.description}
                      status={event.status}
                    />
                  ))
                ) : (
                  <p>Aucun événement passé.</p>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendrier;