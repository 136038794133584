const teams = [
    {
      name: "Équipe 1",
      division: "Départementale 3A",
      image: require('../images/interclub/Mixte1.jpg'),
      results: {
        currentRank: "",
        wins: 0,
        losses: 0,
        achievements: [
        ]
      },
      players: [
        "Sarah	CHTIOUI",
        "Marilyne	DAO",
        "Emilie	BERNARD",
        "Pascal	MOISSERON",
        "Bruno	BERNARD",
        "Cedrick	HATCHI",
        "Damien	MEYBECK",
        "Kevin	BORDY",
      ]
    },
    {
      name: "Équipe 2",
      division: "Départementale 4",
      image: require('../images/interclub/Mixte2.jpg'),
      results: {
        currentRank: "",
        wins: 0,
        losses: 0,
        achievements: [
          "Montée en Départementale 1 en 2023",
          "Vainqueur de la coupe départementale 2022"
        ]
      },
      players: [
        "Aline	BEAUPRETRE",
        "Elaia	JOUHANET",
        "Aurianne	LUDOT",
        "Lucie	PIRES",
        "Baptiste	MARCHAND",
        "Gurvan	MOUILLE",
        "Joel	GAUDIER",
        "Joel	CASTAING",
        "Marc	AULARD",
        "Liam	MICHAEL",
      ]
    },
    {
      name: "Équipe 3",
      division: "Départementale 5",
      image: require('../images/interclub/Masculine.jpg'),
      results: {
        currentRank: "",
        wins: 4,
        losses: 2,
        achievements: [
          "Demi-finaliste de la coupe départementale 2023"
        ]
      },
      players: [
        "ChloÈ	PITMAN-FILLATRE",
        "Aurelie	VALDENAIRE",
        "Barbara	HOSTACHY",
        "Hong Linh	DANG",
        "Maxime	VERVIALLE",
        "Remy	AMAROUCHE",
        "Romain	ANQUETIN",
        "Lucas	DUPUIS",
        "Didier	TETTARASAR",
        "Nam anh	NGUYEN",
        "Remi	AGSOUS",
      ]
    }
  ];

  export {teams}