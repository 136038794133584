import React, { useEffect } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';

const VirtualTour = () => (
  <div className="aspect-w-16 aspect-h-9">
    <iframe style={{border:0}} 
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d905.9648831058183!2d2.11152749168068!3d49.02137971107253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6f54cdfade7bb%3A0xdb2a014c62a8fb68!2sGymnase%20de%20la%20Butte!5e1!3m2!1sen!2sfr!4v1724798967561!5m2!1sen!2sfr" 
    width="100%" height="450" 
    allowfullscreen="" 
    loading="lazy" 
    referrerpolicy="no-referrer-when-downgrade"></iframe>
    
  </div>
);

const Section = ({ title, children, bgColor = 'bg-white' }) => (
  <div className={`${bgColor} py-2`}>
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold">{title}</h2>
      {children}
    </div>
  </div>
);

const Acces = ({ setCurrentSection, currentSection }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);

  //const position = [49.02132616332159, 2.1122717857360844];
  return (
  <>
      <div className='bg-fixed bg-top bg-no-repeat bg-center  min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
        <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
        <div className="text-gray-800 py-40" >
          <div className="container mx-auto text-center px-4">
            <h1 className="text-6xl font-bold mb-10">Accès</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-4">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-semibold mb-4">Adresse</h3>
                <p className="mb-6 text-gray-700">Gymnase de la Butte<br />85 Rue de Pierrelaye<br />95610 Éragny-sur-Oise</p>
                
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
               
                <h3 className="text-2xl font-semibold mb-4">Coordonnées</h3>
                <p className="mb-6 text-gray-700">Latitude : 49.0213261<br/>Longitude : 2.1122717</p>
                
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                
                <h3 className="text-2xl font-semibold mb-4">Transports</h3>
                <p className="mb-6 text-gray-700">RER A : Station Neuville-Université<br/>Bus 49 : Arrêt Gymnase de la Butte</p>
              </div>
            </div>

            <Section  bgColor="bg-stone-100">
              <VirtualTour />
            </Section>
            
          </div>
        </div>
      </div>
    </>
  );
}
  
export default Acces;