import React, { useEffect } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import TableauTarifs from '../components/TableauTarifs';
import { categoriesAdulte, CategoriesDefinitions, categoriesJeunes, creneauxAdulte, creneauxJeunes } from '../data/CreneauxCategories';
import { motion } from 'framer-motion';
import Section from '../components/Section';
import { useParams } from 'react-router-dom';
import TableauCategories from '../components/TableauCategories';


const CreneauxAdultes = () => (
  <Section title="Nos Créneaux Adultes">
    
      <TableauTarifs categories={categoriesAdulte} creneaux={creneauxAdulte}/>
    
  </Section>
);
const CreneauxJeunes = () => (
  <>
  <Section title="Nos Créneaux Jeunes">
    <TableauTarifs categories={categoriesJeunes} creneaux={creneauxJeunes}/>
  </Section>
  
  </>
  
);
const CategoriesDefinition = () => (
  <>
  <Section title="Définition des catégories">
      <TableauCategories TitlesColumn={CategoriesDefinitions.titles} Categories={CategoriesDefinitions.Categories}/>
  </Section>
  </>
  
);

const Creneaux = ({ setCurrentSection, currentSection, activeTab, setActiveTab }) => {

  const {tab} = useParams();
  useEffect(() => {
    if(!tab) setActiveTab(Object.keys(tabContent)[0]);
    else if(!!tab && tab !== activeTab) {setActiveTab(tab)}
  }, [currentSection,tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);

  const tabContent = {
    creneauxAdultes: <CreneauxAdultes />,
    creneauxJeunes: <CreneauxJeunes />,
    CategoriesDefinition: <CategoriesDefinition />
  };

  const tabTitle = {
    creneauxAdultes: "Creneaux Adultes",
    creneauxJeunes: "Creneaux Jeunes",
    CategoriesDefinition:"Definition des catégories"
  };

  return (
    <>
      <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
        <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
        <div className="text-gray-800 py-40">
          <div className="container mx-auto px-4">
            <h1 className="text-5xl font-bold text-center mb-12">Nos créneaux</h1>
            <p className="text-xl text-center mb-12">Découvrez nos offres et horaires pour la saison 2024/2025</p>

            <div className="flex flex-wrap justify-center mb-8">
              {Object.keys(tabContent).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 m-2 rounded ${
                    activeTab === tab ? 'bg-red-800 text-white' : 'bg-stone-200 text-gray-700'
                  }`}
                >
                  {Object.keys(tabTitle).includes(tab) ? tabTitle[tab] : tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {tabContent[activeTab]}
            </motion.div>
            {(activeTab ==='creneauxAdultes' || activeTab==='creneauxJeunes') && 
             <><p className="text-sm text-left font-bold italic">Attention :</p>
              <p className="text-sm text-left mb-12 italic">Les créneaux indiqués sur cette page ne sont pas valables pendant les vacances scolaires. Durant ces périodes, les horaires peuvent être modifiés ou annulés. Veuillez consulter régulièrement nos communications ou nous contacter pour plus d’informations sur les créneaux disponibles pendant les vacances.</p>
              </> 
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Creneaux;