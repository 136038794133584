const entraineurs = [
    {
        nom: "Jade Jouhanet",
        role: "Entraîneur principal",
        description: "Passionnée de badminton depuis son plus jeune âge, Jade Jouhanet a grandi avec ce sport qui a toujours fait partie de sa vie. Ancienne joueuse du club d'Éragny, où elle a fait ses premiers pas, Jade s'est distinguée par ses performances sur la scène nationale. L'an dernier, elle a évolué au sein de l'US Créteil Badminton, et à partir de septembre, elle rejoindra l'US Carrières-sur-Seine. Ses victoires en simple et en double, notamment au tournoi d'Éragny, témoignent de sa polyvalence et de son engagement dans la compétition. Aujourd'hui, elle partage sa passion et son expertise en tant qu'entraîneuse, motivée à accompagner les joueurs dans leur progression.",
        image: require('../images/entraineurs/adultes.jpeg'),
        detailEntrainement:<>
          <h3 className="text-2xl font-semibold mb-4">Spécialités</h3>
          <ul className="list-disc list-inside mb-6">
            <li>Technique avancée de smash</li>
            <li>Stratégie de jeu en double</li>
            <li>Préparation mentale pour la compétition</li>
          </ul>
        </>
      },
      {
        nom: "Baptiste Marchand",
        role: "Coach individuel",
        description: "On ne le présente plus! Expert technique et tactique, il élaborera des programmes sur mesure pour optimiser les performances de chaque joueur.",
        image: require('../images/entraineurs/physique.jpeg'),
        detailEntrainement:<>
        <h3 className="text-2xl font-semibold mb-4">Spécialités</h3>
        <ul className="list-disc list-inside mb-6">
          <li>Technique avancée de smash</li>
          <li>Stratégie de jeu en double</li>
          <li>Préparation mentale pour la compétition</li>
        </ul>
      </>
      },
    {
      nom: "Danh Auduc",
      role: "Entraîneur jeunes",
      description: "Passionné par la formation des jeunes talents, Danh se concentre sur l'apprentissage ludique et le développement des fondamentaux.",
      image: require('../images/entraineurs/jeunes.jpeg')
    }
  ];

  export {entraineurs};