const articles = [
    
    { 
        libelle: "Cordage BG80", 
        description: "Plus fin pour une meilleure réactivité et un excellent contrôle, idéal pour les joueurs avancés. Profitez d'une puissance maximale avec une précision accrue.En cas de cordage cassé, le club propose un service de recordage de raquette via notre partenaire équipementier Lardesport, avec le modèle BG80 disponible pour un jeu optimisé.", 
        visuel :require("../images/boutique/bg80.png"),
        url : "https://www.payasso.fr/badminton-club-eragny/boutique"
    },
    { 
        libelle: "Cordage BG65", 
        description: "Légèrement plus épais pour un confort et une durabilité accrus, parfait pour tous les niveaux. Offrez-vous un bon équilibre entre performance et longévité.En cas de cordage cassé, le club propose un service de recordage de raquette via notre partenaire équipementier Lardesport, avec le modèle BG65 disponible pour un jeu optimisé.", 
        visuel :require("../images/boutique/bg65.png"),
        url : "https://www.payasso.fr/badminton-club-eragny/boutique"
    },
    { 
        libelle: "Volants plume RSL A9", 
        description: "Offrant une performance optimale et une durabilité exceptionnelle, ces volants sont idéals pour les compétitions et les entraînements avancés. Le club propose à la vente des volants plumes homologués pour les compétitions officielles à des prix négociés pour ses adhérents, garantissant ainsi un excellent rapport qualité-prix.",
        visuel :require("../images/boutique/volantsplumes.png"),
        url : "https://www.payasso.fr/badminton-club-eragny/boutique"
    },
    { 
        libelle: "Volants Hybride", 
        description: "Offrant un excellent rapport qualité-prix et une résistance accrue, ces volants sont parfaits pour les joueurs de tous niveaux. Le club propose à la vente, à des prix négociés pour ses adhérents, des volants hybrides idéals pour les jeux libres au sein du club.", 
        visuel :require("../images/boutique/volantshybrides.png"),
        url : "https://www.payasso.fr/badminton-club-eragny/boutique"
    },
    { 
        libelle: "Offre All Inclusive Adultes", 
        description: "Adhéré à l'offre \"All Inclusive\" (réservé uniquement aux adultes) et bénéficiez d’un accès complet à toutes nos activités et services, réservée exclusivement aux adultes. Que vous soyez passionné de compétition ou amateur de loisirs, cette formule vous offre tout ce dont vous avez besoin pour progresser et vivre pleinement votre passion du badminton.", 
        
        url : "https://www.payasso.fr/badminton-club-eragny/boutique"
    }
];
export {articles};