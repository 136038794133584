import React, { useState, useEffect } from 'react';
import logo from '../images/common/logo192.png';

const Header = ({ setCurrentSection, currentSection, isLandingPage }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sections =[
    'accueil', 
    'club', 
    //'entrainements', 
    'inscription',
    'creneaux',
    'agenda', 
    //'interclub',
    'boutique',
    'regles', 
    'acces', 
    'contact',
    'FAQ'
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 50); // Changez 50 par la valeur de défilement à partir de laquelle vous voulez que le changement se produise
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      (isScrolled || !isLandingPage) ? 'bg-white shadow-md py-2 bg-opacity-90' : 'bg-stone-800 bg-opacity-70  py-4'
    }`}>
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="flex items-center" onClick={() => { setCurrentSection('accueil'); setIsMenuOpen(false); }} >
          <div className={`w-12 h-12 rounded-full mr-2 transition-colors duration-300`}>
              <img src={logo} alt="Badminton Club Eragny" className="h-14 rounded" />
          </div>
          <span className={`font-bold text-2xl ${isScrolled || !isLandingPage ? 'text-red-900' : 'text-white'} transition-colors duration-300`}>BCE</span>
        </div>
        <div className="lg:hidden">
          
        </div>

        <div className="lg:hidden">
          <span className={`${isScrolled || !isLandingPage ?'text-gray-800':'text-white'} capitalize text-xl px-4`}>
            {currentSection}
          </span>
          <button onClick={toggleMenu} className={`${isScrolled || !isLandingPage ? 'text-gray-800':'text-white'} focus:outline-none`}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
            </svg>
          </button>
        </div>

        <nav className={`${
          isMenuOpen ? 'block' : 'hidden'
        } lg:block absolute lg:relative top-full left-0 right-0 lg:top-auto lg:flex space-x-4 bg-white lg:bg-transparent`}>
          <ul className="flex flex-col lg:flex-row lg:space-x-4">
            {sections.map((section) => (
              <li key={section} 
              className={`${currentSection === section ?'bg-red-800 lg:bg-transparent lg:text-red-800':''}`}
              onClick={() => { setCurrentSection(section); setIsMenuOpen(false); }} >
                <button 
                  className={`
                    ${isScrolled || !isLandingPage ? (
                      (currentSection === section) ?'text-white lg:bg-white  lg:text-red-800 lg:border lg:border-white lg:rounded-full ':
                      'text-gray-800 hover:text-red-900'
                    )  
                    :(
                      (currentSection === section) ? 
                        'text-white lg:bg-white  lg:text-red-800 lg:border lg:border-white lg:rounded-full ' 
                        : 'text-gray-800 hover:text-red-900 lg:text-white lg:hover:text-gray-700'
                    )}

                    py-2 px-6 lg:py-0 lg:px-1 capitalize transition-colors duration-300  lg:hover:bg-white  lg:hover:text-red-800 lg:border hover:transition-all hover:duration-300  lg:border-transparent lg:rounded-full
                    `}
                >
                  {section}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;