import React from "react";

const TableauCategories = ({TitlesColumn,Categories}) => {

    return (
    <>
    <div className="overflow-x-auto">
        <table className="w-full bg-white border-collapse">
        <thead>
            <tr>
            {TitlesColumn.map((title, index) => (
                <th key={index} className="py-4 px-6 text-center font-normal border">
                    <h3 className="text-xl font-bold ">{title}</h3>
                </th>
            ))}
            </tr>
        </thead>
        <tbody>
            {Categories.map((cat, index) => (
            <tr key={index}>
                <td className="py-4 px-6 text-center border">
                    <h3 className="text-md underline">{cat.name}</h3>
                </td>
                <td className="py-4 px-6 text-center border">
                    <p className="text-md">{cat.value}</p>
                </td>
            </tr>
            ))}
        </tbody>
        </table>
    </div>
    </>);
}

export default TableauCategories;