import React, { useState, useEffect } from 'react';
import { currentEventsData } from '../data/Events';
import { Link } from 'react-router-dom';

const EventCard = ({ event, setCurrentSection, setActiveTab }) => (
  <div className="bg-white rounded-lg shadow-lg p-8">
    <event.title/>

    {/* Responsive Flexbox layout */}
    <div className="flex flex-col md:flex-row md:items-center">
      {event.image && (
        <>
          <div className="flex justify-left mb-4 md:mb-0 md:mr-6">
            <img 
              src={event.image} 
              alt={event.title} 
              className="h-44 text-left rounded-lg"
            />
          </div>
          {/* Description */}
          <div className="text-left">
            <event.description />
            
          </div>
        </>
      )}
      {!event.image &&
        <>
          <div>
            <event.description />
          </div>
          
        </>
      }
      
    </div>
   
   {/* Button logic */}
   {event.button && !!event.button.url && (
      <div className="text-center block">
        <a 
          href={event.button.url} 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block bg-red-800 text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300"
        >
          {event.button.text}
        </a>
      </div>
    )}
    {event.button && !!event.button.section && (
      <div className="text-center">
        <Link
          to={`/${event.button.section}`}
          className="inline-block bg-red-800 text-center text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300"
          onClick={() => { setCurrentSection(event.button.section); setActiveTab(event.button.tab); }}
        >
          {event.button.text}
        </Link>
      </div>
  )}

    

    
  </div>
);

const CurrentEvents = ({ setCurrentSection,setActiveTab }) => {
  const eventDuration = 10;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [timeLeft, setTimeLeft] = useState(eventDuration);
  const currentDate = new Date();

  const activeEvents = currentEventsData
    .filter(event => currentDate >= event.startDate && currentDate <= event.endDate)
    .sort((a, b) => b.startDate - a.startDate);

  useEffect(() => {
    if (activeEvents.length > 1) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime === 0) {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % activeEvents.length);
            return eventDuration;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [activeEvents.length]);

  if (activeEvents.length === 0) {
    return null;
  }

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Actualités du club</h2>
        <div className="relative">
          <div className="overflow-hidden text-center">
            <div 
              className="flex transition-transform duration-1000 ease-in-out"
              style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            >
              {activeEvents.map((event, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <EventCard event={event} setCurrentSection={setCurrentSection} setActiveTab={setActiveTab} />
                </div>
              ))}
            </div>
          </div>
          {activeEvents.length > 1 && (
            <>
              <div className="h-1 bg-gray-300">
                <div 
                  className="h-full bg-red-900 transition-all duration-1000 ease-linear"
                  style={{ width: `${(timeLeft / eventDuration) * 100}%` }}
                ></div>
              </div>
              <div className="flex justify-center mt-4">
                {activeEvents.map((_, index) => (
                  <button
                    key={index}
                    className={`w-3 h-3 rounded-full mx-1 ${index === currentSlide ? 'bg-red-600' : 'bg-gray-300'}`}
                    onClick={() => {
                      setCurrentSlide(index);
                      setTimeLeft(eventDuration);
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default CurrentEvents;