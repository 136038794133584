import React from "react";
import Section from "./Section";
import Card from "./Card";
import { membresBureau } from "../data/MembreBureau";

const Bureau = ({handleButtonClick}) => {
    return (
      <Section title="Le Bureau">
        <div className="grid  grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-8">
        {membresBureau.map((membre, index) => (
            <Card 
            key={index}
            title={membre.nom}
            content={membre.role}
            icon={() => <img src={membre.avatar} alt="membre.nom" className="h-48 rounded" />}
            //buttonText="Voir profil"
            /*onButtonClick={() => handleButtonClick({
              title: membre.nom,
              description: membre.mission
            })}*/
          />
        ))}
        </div>
      </Section>
      
    );
  };

  export default Bureau;