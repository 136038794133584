import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate, Navigate } from 'react-router-dom';
import Accueil from './pages/Accueil';
import Club from './pages/Club';
import Interclub from './pages/Interclub';
import Acces from './pages/Acces';
import Contact from './pages/Contact';
import Calendrier from './pages/Calendrier';
import Regles from './pages/Regles';
import Entraineurs from './pages/Entraineurs';
import Footer from './components/Footer';
import FAQ from './pages/FAQ';
import Inscription from './pages/Inscription';
import Creneaux from './pages/Creneaux';
import Boutique from './pages/Boutique';

const SectionWrapper = ({ Component, defaultTab, section }) => {
  

  const { tab } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tab || defaultTab);

  const setCurrentSection = (newSection, newTab = defaultTab) => {
    navigate(`/${newSection}`);
  };

  const setActiveTabAndUpdateURL = (newTab) => {
    window.scrollTo(0, 0);
    setActiveTab(newTab);
    navigate(`/${section}/${newTab}`);
  };
  
  return (
    <Component
      setCurrentSection={setCurrentSection}
      currentSection={section}
      activeTab={activeTab}
      setActiveTab={setActiveTabAndUpdateURL}
    />
  );
};

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Navigate to="/accueil" replace />} />
            <Route path="/accueil" element={<SectionWrapper Component={Accueil} section="accueil" />} />
            <Route path="/club/:tab?" element={<SectionWrapper Component={Club} defaultTab="histoire" section="club" />} />
            <Route path="/entrainements" element={<SectionWrapper Component={Accueil} section="entrainements" />} />
            <Route path="/inscription/:tab?" element={<SectionWrapper Component={Inscription} section="inscription" defaultTab="modalite" />} />
            <Route path="/creneaux/:tab?" element={<SectionWrapper Component={Creneaux} defaultTab="creneauxAdulte" section="creneaux" />} />
            <Route path="/interclub" element={<SectionWrapper Component={Interclub} section="interclub" />} />
            <Route path="/boutique" element={<SectionWrapper Component={Boutique} section="boutique" />} />
            <Route path="/acces" element={<SectionWrapper Component={Acces} section="acces" />} />
            <Route path="/contact/:tab?" element={<SectionWrapper Component={Contact} defaultTab="contact" section="contact" />} />
            <Route path="/agenda" element={<SectionWrapper Component={Calendrier} section="agenda" />} />
            <Route path="/regles/:tab?" element={<SectionWrapper Component={Regles} defaultTab="regles" section="regles" />} />
            <Route path="/faq" element={<SectionWrapper Component={FAQ} section="faq" />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;