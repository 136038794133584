import React from "react";

const TableauTarifs = ({categories,creneaux}) => {

    return (
    <>
    <div className="overflow-x-auto">
        <table className="w-full bg-white border-collapse">
        <thead>
            <tr>
            <th className="py-4 px-6 text-left border"></th>
            {categories.map((cat, index) => (
                <th key={index} className="py-4 px-6 text-center font-normal border">
                <h3 className="text-xl font-bold mb-2">{cat.nom}</h3>
                <p className="text-sm">{cat.description}</p>
                {cat.subDescription &&
                    <p className="text-sm italic">{cat.subDescription}</p>
                }
                </th>
            ))}
            </tr>
        </thead>
        <tbody>
            {creneaux.map((creneau, index) => (
            <tr key={index}>
                <td className="py-4 px-6 text-left border">
                    <h3 className="text-xl font-bold mb-2">{creneau.jour}</h3>
                    <p className="text-sm">{creneau.horaire}</p>
                </td>
                {categories.map((cat, catIndex) => (
                <td key={catIndex} className="py-4 px-6 text-center border">
                    {cat.creneaux[index] &&
                    <div className="w-6 h-6 bg-red-800 rounded-full mx-auto flex items-center justify-center">
                        <span className="text-white">✓</span>
                    </div>
                    }
                    {cat.specificite[index] && !!creneau.specificite &&<p className="text-sm mt-2 text-gray-400 italic">*{creneau.specificite}</p>}
                </td>
                ))}
            </tr>
            ))}
            <tr>
            <td className="py-4 px-6 text-left border"></td>
            {categories.map((cat, index) => (
                <td key={index} className="py-4 px-6 text-center border">
                <p className="mb-2 font-bold">{cat.tarif}</p>
                <a 
                    href={cat.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="inline-block bg-red-800 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                >
                    {cat.libelleBouton}
                </a>
        
                </td>
            ))}
            </tr>
        </tbody>
        </table>
    </div>
    </>);
}

export default TableauTarifs;