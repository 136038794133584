import React from 'react';
import QRCode from 'react-qr-code';

const QRCodeContent = () => (
  <div className="max-w-3xl mx-auto text-center">
    <h2 className="text-4xl font-semibold mb-6">QR Code d'inscription</h2>
    <p className="mb-4">
      Scannez ce QR code pour accéder directement au formulaire d'inscription en ligne :
    </p>
    <div className="flex justify-center mb-8">
      <QRCode value="https://adherer.myffbad.fr/BCE95" size={256} />
    </div>
    <p className="mt-4">
      Ce QR code vous dirigera vers la page d'inscription officielle du Badminton Club Eragny pour la saison 2024/2025.
    </p>
  </div>
);

export default QRCodeContent;