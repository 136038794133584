import React from "react";
const Card = ({ title, content, icon: Icon, buttonText, onButtonClick, url }) => (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="p-6 text-center">
        <div className="text-4xl text-red-800 mb-4 flex justify-center">
          <Icon />
        </div>
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        <p className="text-gray-700 mb-4">{content}</p>
        {!url && !!buttonText && <button 
          onClick={onButtonClick}
          className="bg-red-800 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
        >
          {buttonText}
        </button>
        }
        {!!url && !!buttonText  && <a 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-block bg-red-800 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
        >
            {buttonText}
        </a>
        }
      </div>
    </div>
  );

  export default Card;