import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTrophy, FaChartLine, FaUsers, FaCalendarAlt } from 'react-icons/fa';

// Importez les images des équipes ici

import { teams } from '../data/Interclubs';

const TeamCard = ({ name, division, image, results, onDetailsClick }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <img src={image} alt={name} className="w-full h-48 object-cover" />
    <div className="p-6">
      <h3 className="text-xl font-bold mb-2">{name}</h3>
      <p className="text-gray-600 mb-4">{division}</p>
      <p className="text-gray-700 mb-4">Classement actuel : {results.currentRank}</p>
      <button 
        onClick={onDetailsClick}
        className="bg-red-800 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
      >
        Voir les détails
      </button>
    </div>
  </div>
);

const TeamModal = ({ team, onClose }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    onClick={onClose}
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
  >
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      onClick={(e) => e.stopPropagation()}
      className="bg-white rounded-lg shadow-xl max-w-2xl w-full overflow-hidden"
    >
      <img src={team.image} alt={team.name} className="w-full h-64 object-cover" />
      <div className="p-8">
        <h2 className="text-3xl font-bold mb-4">{team.name}</h2>
        <p className="text-xl text-gray-600 mb-4">{team.division}</p>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <h3 className="text-lg font-semibold mb-2 flex items-center">
              <FaTrophy className="mr-2 text-yellow-500" /> Palmarès
            </h3>
            <ul className="list-disc list-inside">
              {team.results.achievements.map((achievement, index) => (
                <li key={index}>{achievement}</li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2 flex items-center">
              <FaChartLine className="mr-2 text-blue-500" /> Résultats actuels
            </h3>
            <p>Classement : {team.results.currentRank}</p>
            <p>Victoires : {team.results.wins}</p>
            <p>Défaites : {team.results.losses}</p>
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <FaUsers className="mr-2 text-green-500" /> Composition de l'équipe
          </h3>
          <ul className="list-disc list-inside">
            {team.players.map((player, index) => (
              <li key={index}>{player}</li>
            ))}
          </ul>
        </div>
        <button 
          onClick={onClose}
          className="mt-6 bg-red-800 text-white px-6 py-2 rounded hover:bg-red-700 transition duration-300"
        >
          Fermer
        </button>
      </div>
    </motion.div>
  </motion.div>
);

const Interclub = ({ setCurrentSection, currentSection }) => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);
  
  return (
    <>
      <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
        <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
        <div className="text-gray-800 py-40">
          <div className="container mx-auto px-4">
            <h1 className="text-5xl font-bold text-center mb-12">Nos Équipes Interclub</h1>
            <p className="text-xl text-center mb-12">Découvrez nos équipes et leurs performances dans les compétitions interclubs</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
              {teams.map((team, index) => (
                <TeamCard 
                  key={index}
                  {...team}
                  onDetailsClick={() => setSelectedTeam(team)}
                />
              ))}
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-3xl font-bold mb-6 text-center">Calendrier des prochains matchs</h2>
              <div className="flex items-center justify-center text-6xl text-red-800 mb-4">
                <FaCalendarAlt />
              </div>
              <p className="text-center text-lg">
                Retrouvez le calendrier complet de nos équipes et venez les supporter lors de leurs prochains matchs !
              </p>
              <div className="text-center mt-6">
                <button className="bg-red-800 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300">
                  Voir le calendrier
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {selectedTeam && (
          <TeamModal 
            team={selectedTeam}
            onClose={() => setSelectedTeam(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Interclub;