import React, { useEffect } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import { motion } from 'framer-motion';
import { FaHeartbeat, FaGavel, FaChartLine } from 'react-icons/fa';

// Importez les images nécessaires ici
import imgHistoire from '../images/Règles/badmintonHistory.png';
import imgPrincipes from '../images/Règles/BadmintonRules.png';
import imgFrappes from '../images/Règles/BadmintonFrappe.png';
import { useParams } from 'react-router-dom';

const Section = ({ title, children, icon: Icon }) => (
  <div className="mb-8">
    <h2 className="text-2xl font-bold mb-4 flex items-center">
      {Icon && <Icon className="mr-2 text-red-800" />}
      {title}
    </h2>
    {children}
  </div>
);

const Regles = ({ setCurrentSection, currentSection, activeTab, setActiveTab }) => {
  
  
  const tabContent = {
    histoire: (
      <Section title="Histoire du Badminton">
        <img src={imgHistoire} alt="Histoire du Badminton" className="w-full h-64 object-cover rounded-lg mb-4" />
        <p className="mb-4">
          Inventé au 19ème siècle au Royaume-Uni, le badminton est un héritage du "poona", un jeu de raquette indien. 
          Il est devenu une discipline olympique en 1992 et est aujourd'hui le deuxième sport le plus pratiqué au monde après le football.
        </p>
        <p>
          Le record de vitesse pour un volant en compétition est de 426 km/h, établi en 2017 par Mads Pieler Kolding. 
          En conditions contrôlées, le record atteint même 565 km/h !
        </p>
      </Section>
    ),
    principes: (
      <Section title="Principes de Base">
        <img src={imgPrincipes} alt="Principes de base du Badminton" className="w-full h-64 object-cover rounded-lg mb-4" />
        <ol className="list-decimal pl-6 space-y-2">
          <li>Le badminton se joue en simple (1 contre 1) ou en double (2 contre 2).</li>
          <li>Le service alterne entre la droite (score pair) et la gauche (score impair).</li>
          <li>On utilise une raquette et un volant composé de 16 plumes et d'un bouchon en liège.</li>
          <li>Un tirage au sort ("toss") détermine qui sert ou choisit son terrain en premier.</li>
          <li>Le service s'effectue du bas vers le haut, en frappant le volant sous la ceinture.</li>
        </ol>
      </Section>
    ),
    regles: (
      <Section title="Règles Importantes" icon={FaGavel}>
        <ul className="list-disc pl-6 space-y-2">
          <li>Le filet fait partie du jeu. Un volant qui le touche reste en jeu s'il passe de l'autre côté.</li>
          <li>Les pieds du serveur ne doivent pas toucher les lignes au moment du service.</li>
          <li>Une faute est commise si le volant touche le sol hors des limites ou est frappé deux fois de suite.</li>
          <li>Il est interdit de distraire ou de provoquer l'adversaire.</li>
          <li>Un match se joue en deux sets gagnants de 21 points (avec 2 points d'écart, jusqu'à 30 maximum).</li>
        </ul>
      </Section>
    ),
    frappes: (
      <Section title="Principes de Frappe">
        <img src={imgFrappes} alt="Principes de frappe au Badminton" className="w-full h-64 object-cover rounded-lg mb-4" />
        <ul className="list-disc pl-6 space-y-2">
          <li><strong>Dégagement défensif :</strong> Frappe haute et longue pour repousser l'adversaire au fond du court.</li>
          <li><strong>Dégagement offensif :</strong> Frappe puissante et rapide visant à mettre l'adversaire en difficulté.</li>
          <li><strong>Drive :</strong> Frappe à mi-hauteur, rapide et tendue, parallèle au filet.</li>
          <li><strong>Smash :</strong> Frappe offensive puissante, du haut vers le bas, visant à marquer le point.</li>
          <li><strong>Amorti :</strong> Frappe douce et précise, juste derrière le filet, pour surprendre l'adversaire.</li>
          <li><strong>Contre-amorti :</strong> Réponse courte à un amorti, maintenant le volant près du filet.</li>
        </ul>
      </Section>
    ),
    classement: (
      <Section title="Classement et Compétition" icon={FaChartLine}>
        <p className="mb-4">
          La Fédération Française de Badminton (FFBaD) utilise un système de points pour classer les joueurs. 
          Les débutants commencent non classés (NC), puis progressent vers les niveaux P (Promotion), D (Départemental), R (Régional), et N (National).
        </p>
        <p>
          Le classement est calculé sur la base des 6 meilleurs résultats en compétition au cours des 12 derniers mois.
        </p>
      </Section>
    ),
    bienfaits: (
      <Section title="Bienfaits du Badminton" icon={FaHeartbeat}>
        <ul className="list-disc pl-6 space-y-2">
          <li>Sport très énergivore : environ 500 kcal dépensées en une heure.</li>
          <li>Améliore l'endurance, la coordination et les réflexes.</li>
          <li>Raffermit les cuisses, les fessiers et les mollets.</li>
          <li>Booste le moral en libérant des endorphines.</li>
        </ul>
      </Section>
    ),
  };

  const {tab} = useParams();
  useEffect(() => {
    if(!tab) setActiveTab(Object.keys(tabContent)[0]);
    else if(!!tab && tab !== activeTab) {setActiveTab(tab)}
  }, [currentSection,tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);

  const tabTitle = {
  };

  return (
    <>
      <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
        <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
        <div className="text-gray-800 py-40">
          <div className="container mx-auto px-4">
            <h1 className="text-5xl font-bold text-center mb-12">Règles et Fonctionnement du Badminton</h1>
            
            <div className="flex flex-wrap justify-center mb-8">
              {Object.keys(tabContent).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 m-2 rounded ${
                    activeTab === tab ? 'bg-red-800 text-white' : 'bg-stone-200 text-gray-700'
                  } transition duration-300`}
                >
                  {Object.keys(tabTitle).includes(tab) ? tabTitle[tab] : tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              {tabContent[activeTab]}
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Regles;