import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { faqData } from '../data/FaqData';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold">{question}</span>
        {isOpen ? <FaChevronUp className="text-red-800" /> : <FaChevronDown className="text-red-800" />}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="mt-2 text-gray-600"
          >
            {answer}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = ({ setCurrentSection, currentSection,setActiveTab }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);
    return (
    <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
      <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
      <div className="text-gray-800 py-40">
        <div className="container mx-auto px-4">
          <h1 className="text-5xl font-bold text-center mb-12">Foire Aux Questions</h1>
          <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8">
            {faqData.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={<item.answer setCurrentSection={setCurrentSection} setActiveTab={setActiveTab}/>} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;