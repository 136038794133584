const categoriesAdulte = [
    { 
      nom: "Adultes", 
      tarif: "170€ / an", 
      creneaux: [true, false, false, true,false,true],
      specificite: [false, false, false, false,false,false],
      description: "Accès à 2 séances sans entrainement",
      url:"https://adherer.ffbad.club/bce95",
      libelleBouton:"Inscrivez vous en ligne"
    },
    { 
      nom: "Offre All incluse", 
      tarif: "190€ / an", 
      creneaux: [true, true, true, true,true,true],
      specificite: [true, true, true, true,true,true],
      description: "Accès à tous les créneaux adulte, coaching individuel, entrainement",
      url:"https://adherer.ffbad.club/bce95",
      libelleBouton:"Inscrivez vous en ligne"

    },
  ];

  const currentYear = new Date().getFullYear();

  const categoriesJeunes = [
    { 
      nom: "MiniBads", 
      tarif: "150€ / an", 
      creneaux: [false, false, true],
      specificite: [false, false, false],
      description: "(entre 5 et 8 ans)",
      url:"https://adherer.ffbad.club/bce95",
      libelleBouton:"Inscrivez vous en ligne"
    },
    { 
      nom: "Poussins / Benjamins", 
      tarif: "150€ / an", 
      creneaux: [false, true, false],
      specificite: [false, false, false],
      description: "(entre 9 et 10 ans)",
      url:"https://adherer.ffbad.club/bce95",
      libelleBouton:"Inscrivez vous en ligne"
    },
    { 
      nom: "Minimes / cadets / Junior", 
      tarif: "170€ / an", 
      creneaux: [true, false, false],
      specificite: [false, false, false],
      description: "(entre 11 et 17 ans)",
      url:"https://adherer.ffbad.club/bce95",
      libelleBouton:"Inscrivez vous en ligne"
    },
  ];

  const creneauxAdulte = [
    {jour:"Lundi",horaire:"20h - 22H"},
    {jour:"Mardi",horaire:"20h - 22H", specificite:"Interclub équipe + cours individuels Baptiste"},
    {jour:"Mercredi",horaire:"20h - 22H", specificite:"Entrainement Jade (confirmé) et Baptiste (débutant et intermédiaire)"},
    {jour:"Jeudi",horaire:"20h - 22H", specificite:"Cours individuels Baptiste"},
    {jour:"Vendredi",horaire:"19h30 - 22H", specificite:"Invitation/échanges avec d’autres clubs"},
    {jour:"Samedi",horaire:"9H - 13H"}
  ];

  const creneauxJeunes = [    
    {jour:"Mercredi",horaire:"18H - 20h"},
    {jour:"Vendredi",horaire:"18h30 - 19h30"},
    {jour:"Samedi",horaire:"10H30 - 12H"}
  ];

  

  const CategoriesDefinitions = {
    titles: ["Catégorie d'âge", "Année de naissance"],
    Categories: [
      {
        name: "Minibad 1, 2",
        value: `${currentYear - 7} - ${currentYear - 6}`
      },
      {
        name: "Poussin 1",
        value: `${currentYear - 8}`
      },
      {
        name: "Poussin 2",
        value: `${currentYear - 9}`
      },
      {
        name: "Benjamin 1",
        value: `${currentYear - 10}`
      },
      {
        name: "Benjamin 2",
        value: `${currentYear - 11}`
      },
      {
        name: "Minime 1",
        value: `${currentYear - 12}`
      },
      {
        name: "Minime 2",
        value: `${currentYear - 13}`
      },
      {
        name: "Cadet 1",
        value: `${currentYear - 14}`
      },
      {
        name: "Cadet 2",
        value: `${currentYear - 15}`
      },
      {
        name: "Junior 1",
        value: `${currentYear - 16}`
      },
      {
        name: "Junior 2",
        value: `${currentYear - 17}`
      },
      {
        name: "Sénior",
        value: `<= ${currentYear - 18}` // Sénior correspond à toute personne de 18 ans ou plus
      },
      {
        name: "Vétéran 1",
        value: `${currentYear - 39} - ${currentYear - 35}` // 35 à 39 ans
      },
      {
        name: "Vétéran 2",
        value: `${currentYear - 44} - ${currentYear - 40}` // 40 à 44 ans
      },
      {
        name: "Vétéran 3",
        value: `${currentYear - 49} - ${currentYear - 45}` // 45 à 49 ans
      },
      {
        name: "Vétéran 4",
        value: `${currentYear - 54} - ${currentYear - 50}` // 50 à 54 ans
      },
      {
        name: "Vétéran 5",
        value: `${currentYear - 59} - ${currentYear - 55}` // 55 à 59 ans
      },
      {
        name: "Vétéran 6",
        value: `${currentYear - 64} - ${currentYear - 60}` // 60 à 64 ans
      },
      {
        name: "Vétéran 7",
        value: `${currentYear - 69} - ${currentYear - 65}` // 65 à 69 ans
      },
      {
        name: "Vétéran 8",
        value: `${currentYear - 70}` // 70 ans et plus
      }
    ]
  };


  export {categoriesAdulte,categoriesJeunes,creneauxAdulte,creneauxJeunes,CategoriesDefinitions};