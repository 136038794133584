import React, { useState } from 'react';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';



const Gallery = ({title,images}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const imageGallery = (!!images && images.length > 0) ? images : [
    { src: "/api/placeholder/300/200", alt: "Terrain de badminton" },
    { src: "/api/placeholder/300/200", alt: "Tournoi de badminton" },
    { src: "/api/placeholder/300/200", alt: "Jeunes joueurs de badminton" },
    { src: "/api/placeholder/300/200", alt: "Groupe de joueurs" }
  ];

  const openGallery = (index) => {
    setSelectedImageIndex(index);
    document.body.style.overflow = 'hidden';
  };

  const closeGallery = () => {
    setSelectedImageIndex(null);
    document.body.style.overflow = 'auto';
  };

  const navigate = (direction) => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex >= images.length) return 0;
      if (newIndex < 0) return images.length - 1;
      return newIndex;
    });
  };

  return (
    <section className="py-16 bg-stone-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">{title}</h2>
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6`}>
          {imageGallery.map((image, index) => (
            <div key={index} className="rounded-lg overflow-hidden shadow-lg cursor-pointer transform transition duration-300 hover:scale-105" onClick={() => openGallery(index)}>
              <img src={image.src} alt={image.alt} className="w-full h-48 object-cover" />
            </div>
          ))}
        </div>
      </div>

      {selectedImageIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
          <button onClick={closeGallery} className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors">
            <XMarkIcon className="h-8 w-8" />
          </button>
          <button onClick={() => navigate(-1)} className="absolute left-4 text-white hover:text-gray-300 transition-colors">
            <ChevronLeftIcon className="h-12 w-12" />
          </button>
          <button onClick={() => navigate(1)} className="absolute right-4 text-white hover:text-gray-300 transition-colors">
            <ChevronRightIcon className="h-12 w-12" />
          </button>
          <div className="max-w-4xl max-h-full p-4">
            <img 
              src={images[selectedImageIndex].src} 
              alt={images[selectedImageIndex].alt} 
              className="max-w-full max-h-[90vh] object-contain animate-fadeIn"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Gallery;