import React, { useEffect } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import ListeArticles from '../components/ListeArticles';

const Boutique = ({ setCurrentSection, currentSection, activeTab, setActiveTab }) => {

    return (
        <>
          <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
            <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
            <div className="text-gray-800 py-40">
              <div className="container mx-auto px-4">
                <h1 className="text-5xl font-bold text-center mb-8">Boutique en ligne</h1>
                <p className="text-sm text-center font-bold text-red-800">Réservé uniquement aux adhérents du club!!</p>
                <p className="text-xl text-center mb-12">Découvrez les services et articles proposés par le club.</p>
                
                <div className="flex flex-wrap justify-center mb-8">
                  <ListeArticles/>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}
export default Boutique;