import React, { useEffect } from 'react';
import Header from '../components/Header';
import imgHeader from '../images/common/header.png';
import { motion } from 'framer-motion';
import Section from '../components/Section';
import { useParams } from 'react-router-dom';
import QRCodeContent from '../components/QRCodeContent';



const InscriptionContent = () => (
  <div className="max-w-3xl mx-auto">
    <h2 className="text-4xl font-semibold mb-6 text-center">Inscription pour la Saison 2024/2025</h2>
    <p className="mb-4">
      Les inscriptions pour la saison 2024/2025 sont ouvertes ! Que vous soyez débutant ou joueur confirmé, 
      adulte ou jeune, le Badminton Club Eragny vous accueille pour une nouvelle année sportive riche en 
      émotions et en progrès.
    </p>
    <p className="mb-4">
      Pour vous inscrire, nous avons simplifié le processus en le rendant entièrement en ligne. 
      Suivez simplement le lien ci-dessous pour compléter votre inscription :
    </p>
    <div className="text-center mb-8">
      <a 
        href="https://adherer.ffbad.club/bce95" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="bg-red-800 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300 inline-block"
      >
        S'inscrire pour la saison 2024/2025
      </a>
    </div>
    <div className="border-l-4 border-red-800 pl-4 mb-4 bg-red-50 p-4">
      <p className="font-semibold mb-2">
        Nouveauté cette année : le certificat médical n'est plus obligatoire si vous répondez "non" à toutes les questions du questionnaire de santé.
      </p>
      <p className="text-sm italic text-gray-700">
        Pour votre sécurité et votre bien-être, nous vous prions de répondre en toute honnêteté au questionnaire de santé. Votre santé est notre priorité, et ces questions sont conçues pour assurer votre sécurité lors de la pratique du badminton.
      </p>
    </div>
    <p className="mb-4">
      Veuillez préparer les éléments suivants pour votre inscription :
    </p>
    <ul className="list-disc list-inside mb-4">
      <li>Le questionnaire de santé complété (disponible lors de l'inscription en ligne)<br/>
      <a 
        href={require("../data/documents/FFBaD_-_ADULTES_QUESTIONNAIRE_SANTE.pdf")}
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-600 hover:text-blue-800 underline"
      >
        Télécharger le questionnaire de santé pour Adulte
      </a><br/><a 
        href={require("../data/documents/FFBaD_-_MINEURS_QUESTIONNAIRE_SANTE.pdf")}
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-600 hover:text-blue-800 underline"
      >
        Télécharger le questionnaire de santé pour Mineurs
      </a></li>
      <li>Une photo d'identité récente (format numérique)</li>
      <li>Le règlement de la cotisation (paiement en ligne disponible)</li>
    </ul>
    <div className="bg-stone-100 p-4 border-l-4 border-gray-500 shadow-md mb-4">
      <p className="text-gray-600 italic mb-2">
        Si vous avez répondu "oui" à l'une des questions du questionnaire de santé, un certificat médical sera nécessaire.
      </p>
      <a 
        href={require("../data/documents/GUI21F1_ADM_Certificat de non contre-indication.pdf")}
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-600 hover:text-blue-800 underline"
      >
        Télécharger le formulaire de certificat médical
      </a>
    </div>
    <p className="mt-4">
      Pour toute question concernant les inscriptions ou les créneaux, n'hésitez pas à nous contacter 
      via notre formulaire de contact ou directement par email à secretariatbce@gmail.com.
    </p>
  </div>
);

const PassSport = () => (
  <Section title="Le Pass'Sport">
    <div className="max-w-3xl mx-auto">
      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">1. Qu'est-ce que le Pass'Sport ?</h3>
        <p className="mb-4">
          Le Pass'Sport est une nouvelle allocation de rentrée sportive. Cette aide de l'État, d'un montant de 50 euros par enfant, permettra de prendre une adhésion dans une association sportive dès septembre 2024 durant toute l'année scolaire 2024-2025.
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">2. Qui est concerné ?</h3>
        <p className="mb-4">
          Le Pass'Sport s'adresse aux enfants de 6 à 17 ans révolus au 30 juin 2024 qui bénéficient soit de :
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>l'allocation de rentrée scolaire ;</li>
          <li>l'allocation d'éducation de l'enfant handicapé ;</li>
          <li>l'allocation aux adultes handicapés (entre 16 et 18 ans).</li>
        </ul>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">3. Validité du dispositif Pass'Sport ?</h3>
        <p className="mb-4">
          Le coupon Pass'Sport est valable du 1er juillet 2024 jusqu'au 31 décembre 2024. Après cette date, les instances ne pourront plus se faire rembourser.
        </p>
      </div>
      <div className="bg-stone-100 p-4 border-l-4 border-gray-500 shadow-md mb-4">
        <p className="font-semibold mb-2">Comment utiliser votre Pass'Sport :</p>
        <ol className="list-decimal list-inside">
          <li>Lors de votre inscription en ligne, sélectionnez la reduction "Pass'Sport"</li>
          <li>Ajouter votre pass Sport en tant que justificatif à la réduction</li>
        </ol>
      </div>
      <div className="text-center mt-8">
        <a 
          href="https://adherer.ffbad.club/bce95" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="bg-red-800 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300 inline-block"
        >
          S'inscrire avec le Pass'Sport
        </a>
      </div>
    </div>
  </Section>
);


// ... (previous code remains the same)

const CouponSport = () => (
  <Section title="Coupon Sport ANCV">
    <div className="max-w-3xl mx-auto">
      <h3 className="text-2xl font-semibold mb-4">Paiement par Coupon Sport ANCV</h3>
      <p className="mb-4">
        Le Badminton Club Eragny est heureux d'accepter les Coupons Sport ANCV comme moyen de paiement pour votre adhésion.
      </p>
      <p className="mb-4">
        Les Coupons Sport ANCV sont une aide à la pratique sportive. Ils permettent de régler les adhésions, licences, abonnements, cours et stages sportifs à moindre coût.
      </p>
      <div className="bg-stone-100 p-4 border-l-4 border-gray-500 shadow-md mb-4">
        <p className="font-semibold mb-2">Comment utiliser vos Coupons Sport ANCV :</p>
        <ol className="list-decimal list-inside">
          <li>Lors de votre inscription en ligne, sélectionnez "Autre moyen de paiement"</li>
          <li>Apportez vos Coupons Sport lors de votre premier entraînement</li>
          <li>Remettez-les à un responsable du club qui validera votre paiement</li>
        </ol>
      </div>
      <p className="mt-4">
        Pour toute question concernant le paiement par Coupon Sport ANCV, n'hésitez pas à nous contacter à l'adresse : <a href="mailto:secretariatbceragny@gmail.com" className="text-red-800 hover:underline">secretariatbceragny@gmail.com</a>
      </p>
      <div className="text-center mt-8">
        <a 
          href="https://adherer.ffbad.club/bce95" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="bg-red-800 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300 inline-block"
        >
          Inscrivez vous
        </a>
      </div>
    </div>
  </Section>
);

const Inscription = ({ setCurrentSection, currentSection, activeTab, setActiveTab }) => {

  const {tab} = useParams();
  useEffect(() => {
    if(!tab) setActiveTab(Object.keys(tabContent)[0]);
    else if(!!tab && tab !== activeTab) {setActiveTab(tab)}
  }, [currentSection,tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);

  const tabContent = {
    modalite: <InscriptionContent />,
    passSport: <PassSport />,
    couponSport: <CouponSport />,
    qrCode : <QRCodeContent/>
  };

  const tabTitle = {
    modalite: "Modalités d'inscription",
    passSport: "Pass'Sport",
    couponSport: "Coupon Sport ANCV",
    qrCode: "QR Code d'inscription"
  };

  return (
    <>
      <div className='bg-fixed bg-top bg-no-repeat bg-center min-[1312px]:bg-cover' style={{backgroundImage: `url(${imgHeader})`}}>
        <Header setCurrentSection={setCurrentSection} currentSection={currentSection} isLandingPage={true} />
        <div className="text-gray-800 py-40">
          <div className="container mx-auto px-4">
            <h1 className="text-5xl font-bold text-center mb-12">Inscription</h1>

            <div className="flex flex-wrap justify-center mb-8">
              {Object.keys(tabContent).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 m-2 rounded ${
                    activeTab === tab ? 'bg-red-800 text-white' : 'bg-stone-200 text-gray-700'
                  }`}
                >
                  {Object.keys(tabTitle).includes(tab) ? tabTitle[tab] : tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {tabContent[activeTab]}
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inscription;